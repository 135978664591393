import { render, staticRenderFns } from "./ProductBox.vue?vue&type=template&id=67598f1c&scoped=true&"
import script from "./ProductBox.vue?vue&type=script&lang=js&"
export * from "./ProductBox.vue?vue&type=script&lang=js&"
import style0 from "./ProductBox.vue?vue&type=style&index=0&id=67598f1c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67598f1c",
  null
  
)

export default component.exports